import React from 'react';

export default function RegisterCta() {

    return (
        <section className="cta cta-3">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 text-center">
                        <h2 className="heading">DJ Manager can help your business grow. <br/> <small id="slogan">Save time and start planning with the pros.</small></h2>
                        <a href="https://app.djmanager.co.uk/register"><button className="btn flat-btn -blue -rounded -single">14-Day Free Trial</button></a>
                    </div>
                </div>
            </div>
        </section>
    );

};