import * as React from "react";
import WebsiteLayout from "../layouts/WebsiteLayout";

import macbookFrontImage from '../img/macbook-front.png';
import RegisterCta from "../components/RegisterCta";
import { Link } from "gatsby";

const About = (props) => {
  return (
    <main>
        <WebsiteLayout location={props.location} title="About">
        <section className="content-header content-header-1 -blue">
    <div className="container">
        <div className="row">
            <div className="col-md-12">
                <h1 className="heading">About</h1>
            </div>
        </div>
    </div>
</section>

<section className="content content-2 -bg-grey">
    <div className="container">

        <div className="row">

            <div className="col-md-12">
                    <h3 className="-no-margin-top">About DJ Manager?</h3>
                    <p>
                        DJ Manager is an online service built from the ground up to be a powerful set of tools you can utilise in your business. We can sweat the small stuff and give you the freedom to get back time and manage your business more effectively.
                    </p>
                    <p>
                        The software was initially started back in 2015 by Dan Coleman, the Lead Developer, who holds various IT qualifications including a degree in Computing and Internet Technologies. </p>
                    <p>
                        In the running of his events business, he found he needed a place to input and track all of the related information for his events. Existing solutions on the market often felt outdated and were not flexible enough for his needs.
                    </p>
                    <p>
                        The first version was only integrated into the DJ website directly, but after showing his app to another local DJ, Dan felt motivated to share the tools with others and so DJ Manager was born.
                    </p>
                    <p>
                        The software now hosts a wide range of features and is accessible from any device worldwide allowing you to instantly manage your business. We build on top of years of industry experience managing IT systems to bring you the best experience possible.
                    </p>
            </div>

        </div>

        <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <hr />
            </div>
        </div>

        <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <img src={macbookFrontImage} className="img-responsive" alt="The DJ Manager Application" />
                <br />
            </div>

            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <h3 className="-no-margin-top">Here are just some of our features:</h3>
                <p>
                    <li>Advanced event and contacts Management</li>
                    <li>CRM features such as lead and quotation tracking</li>
                    <li>Venue and supplier Management</li>
                    <li>Document and E-Signature capabilities</li>
                    <li>Financial management</li>
                    <li>iCalendar support, email integration.</li>
                    <li>Embeddable web tools</li>
                </p>
                <Link to={"/features"}><button className="btn flat-btn -blue pull-right">Full Features List</button></Link>
            </div>
        </div>
    </div>
</section>

<section className="content content-6 -inverse">
    <div className="container">
        <div className="row">
            <div className="fact-statistic col-sm-4 col-md-4"><span className="number">1500+</span><span className="statistic">Managed Events</span></div>
            <div className="fact-statistic col-sm-4 col-md-4"><span className="number">Modern</span><span className="statistic">User Interface</span></div>
            <div className="fact-statistic col-sm-4 col-md-4"><span className="number">99.9%</span><span className="statistic">App Uptime</span></div>
        </div>
    </div>
</section>

<RegisterCta />
        </WebsiteLayout>
    </main>
  )
}

export default About;

